export const downloadFile = (data: any, fileName: string) => {
  var blob = new Blob([data], { type: 'application/octetstream' });
  const doc: any = document;

  //Check the Browser type and download the File.
  var isIE = false || !!doc.documentMode;
  if (isIE) {
    (window.navigator as any).msSaveBlob(blob, fileName);
  } else {
    var url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    var a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const downloadFromUrl = (url: string, fileName: string) => {
  //Create XMLHTTP Request.
  var req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    //Convert the Byte Data to BLOB object.
    downloadFile(req.response, fileName);
  };
  req.send();
};

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        return resolve(reader.result);
      }

      return reject('Could not read file');
    };
    reader.onerror = (error) => reject(error);
  });

export const getFileField = (fieldValue: string | File) => {
  if (fieldValue instanceof File) {
    return toBase64(fieldValue);
  }

  return fieldValue;
};
