import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { ApiErrors, Apis } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { useCountdown, useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import styles from './LoginForm.module.scss';
import {
  LoginFormProps,
  LoginFormValues,
  ResendFormValues,
  TokenFormValues,
} from './LoginForm.props';

const LoginForm = ({ ...props }: LoginFormProps) => {
  const { t } = useTranslation();
  const { authService } = useTheme();
  const [error, setError] = useState('');
  const countdown = useCountdown(10);

  const [smsToken, setSmsToken] = useState<string>();
  const [email, setEmail] = useState<string>();

  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const validationSchemaToken = Yup.object().shape({
    smsToken: Yup.string().required(),
    token: Yup.string().required(),
  });

  const handleLogin = useCallback(
    async (values: LoginFormValues) => {
      setError('');

      try {
        const response = await authService.login(values);
        setSmsToken(response.smsToken);
        setEmail(values.email);
      } catch (e: any) {
        if (e.response?.data?.code === ApiErrors.INVALID_CREDENTIALS) {
          setError(t('VALIDATION.LOGIN.INCORRECT_CREDENTIALS'));
        } else {
          setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
          Sentry.captureException(e);
        }
      }
    },
    [authService, t],
  );

  const handleSmsToken = useCallback(
    async (values: TokenFormValues) => {
      setError('');

      try {
        const response = await authService.token(values);

        Storage.setAccessToken(Apis.MAIN, {
          ...response,
        });

        window.location.reload();
      } catch (e) {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
        Sentry.captureException(e);
      }
    },
    [authService, t],
  );

  const handleSmsTokenResend = useCallback(
    (values: ResendFormValues) => async () => {
      setError('');
      countdown.start();

      try {
        const response = await authService.resend(values);

        setEmail(response.email);
        setSmsToken(response.smsToken);
      } catch (e) {
        setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
        Sentry.captureException(e);
      }
    },
    [authService, countdown, t],
  );

  if (smsToken && email) {
    return (
      <Form.Base
        className={styles.LoginForm}
        onSubmit={handleSmsToken}
        error={error}
        validationSchema={validationSchemaToken}>
        <Form.Layout.Field translationKey="AUTHENTICATION.LOGIN.TOKEN" className="mb-0">
          <Form.Input.Text key="token" name="token" defaultValue="" />
        </Form.Layout.Field>

        <Button
          type="button"
          link
          className="mb-sm"
          disabled={countdown.count !== 0}
          size="small"
          onClick={handleSmsTokenResend({ smsToken, email })}>
          {t('AUTHENTICATION.LOGIN.RESEND')} {countdown.count > 0 && `(${countdown.count})`}
        </Button>

        <Form.Input.Hidden name="smsToken" defaultValue={smsToken} />
        <Form.Input.Hidden name="email" defaultValue={email} />

        <Button block type="submit">
          {t('AUTHENTICATION.LOGIN.SUBMIT_LABEL')}
        </Button>
      </Form.Base>
    );
  } else {
    return (
      <div className={styles.LoginForm}>
        <Form.Base
          className={styles.LoginForm}
          onSubmit={handleLogin}
          error={error}
          validationSchema={validationSchemaLogin}
          {...props}>
          <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.EMAIL">
            <Form.Input.Text name="email" />
          </Form.Layout.Field>

          <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.PASSWORD">
            <Form.Input.Text type="password" name="password" />
          </Form.Layout.Field>

          <Button block type="submit">
            {t('AUTHENTICATION.LOGIN.SUBMIT_LABEL')}
          </Button>
        </Form.Base>

        <div className={styles.Footer}>
          <Link to="forgot-password">{t('AUTHENTICATION.LOGIN.FORGOT_PASSWORD')}</Link>
        </div>
      </div>
    );
  }
};

export default LoginForm;
